import { Injectable } from '@angular/core';
import { ExamCenterMemberShip } from '../../models/examCenterMembership';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ExamCenterMembershipService {
  constructor(private http: HttpClient) { }

  list(): Observable<Array<ExamCenterMemberShip>> {
    return this.http.get<Array<ExamCenterMemberShip>>(`${environment.baseApiUrl}/exam_center_memberships/`)
      .pipe(map((res: any) => res.map((d: any) => new ExamCenterMemberShip(d))));
  }

  update(id: number, data: any) {
    return this.http.put<ExamCenterMemberShip>(`${environment.baseApiUrl}/exam_center_memberships/${id}/`, data).pipe(map((res: any) => new ExamCenterMemberShip(res)));
  }

  create(data: any) {
    return this.http.post<ExamCenterMemberShip>(`${environment.baseApiUrl}/exam_center_memberships/`, data).pipe(map((res: any) => new ExamCenterMemberShip(res)));
  }

  destroy(id: number) {
    return this.http.delete<ExamCenterMemberShip>(`${environment.baseApiUrl}/exam_center_memberships/${id}/`);
  }
}
