<div class="api-container">
    <h2>Generate API Key</h2>

    <button (click)="generateApiKey()" [disabled]="loading" class="btn btn-primary">
        {{ loading ? 'Génération...' : 'Générer une nouvelle clé API' }}
    </button>

    <div *ngIf="apiKey" class="api-key-container">
        <p><strong>Votre clé API:</strong></p>
        <div class="api-key">
            <code>{{ apiKey }}</code>
        </div>
        <p class="api-key-info">
            Copiez cette clé API et conservez là en lieu sûr.<br>
            Pour utiliser l'API, ajoutez cette clé dans le header de vos requêtes HTTP, comme suit:<br>
            <code>X-API-Key: {{ apiKey }}</code><br>
            En cas de perte, vous devez générer une nouvelle clé API.<br>
            <br>
            Les endpoints de l'API sont les suivantes :<br>
            <code>GET https://backend-staging.media-cert.fr/external-api/exams/</code>
        </p>
    </div>

    <div *ngIf="errorMessage" class="error">
        {{ errorMessage }}
    </div>
</div>