import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../../environments/environment";
import {map} from "rxjs/operators";
import { Preregistration } from "../../models/preregistration";
import { ExamSession } from "../../models/examSession";
import { Exam } from "../../models/exam";

@Injectable({
    providedIn: "root"
})
export class PreregistrationService {

    constructor (private http: HttpClient) { }

    create (
        firstname: string,
        lastname: string,
        email: string,
        session: ExamSession,
        phoneNumber: string | null = null,
        exam: Exam | null = null,
        fileNumber: string | null = null,
        byPassWarning: boolean = false,
    ): Observable<Preregistration> {
        
        return this.http.post<Preregistration>(
            `${environment.baseApiUrl}/preregistrations/`,
            {
                first_name: firstname,
                last_name: lastname,
                email,
                phone_number: phoneNumber,
                exam_id: exam?.id,
                session_id: session.id,
                file_number: fileNumber,
                by_pass_warning: byPassWarning
            }
        ).pipe(map((res) => new Preregistration(res)));
    }

    list (page?: number, term?: string, sessionId: number | null = null): Observable<Array<Preregistration>> {
        let params = new HttpParams();

        if (term) {
            params = params.set(
                "term",
                `${term}`
            );
        }

        if (page) {
            params = params.set(
                "page",
                `${page}`
            );
        }

        if (sessionId) {
            params = params.set(
                "session_id",
                `${sessionId}`
            );
        }

        return this.http.get<Array<Preregistration>>(
            `${environment.baseApiUrl}/preregistrations/`,
            {params}
        ).
            pipe(map((res) => res.map((d) => new Preregistration(d))));
    }

    retrieve (id: number | string) {
        return this.http.get<Preregistration>(`${environment.baseApiUrl}/preregistrations/${id}/`).
            pipe(map((res) => new Preregistration(res)));
    }

    count (term: string, sessionId: number | null = null): Observable<number> {
        let params = new HttpParams();

        if (term) {
            params = params.set(
                "term",
                `${term}`
            );
        }

        if (sessionId) {
            params = params.set(
                "session_id",
                `${sessionId}`
            );
        }
       
        return this.http.get<number>(
            `${environment.baseApiUrl}/preregistrations/count/`,
            {params}
        );
    }

    update (
        id: number | string,
        firstname: string,
        lastname: string,
        email: string,
        phoneNumber: string | null,
        sessionId: number,
        examId: number | null,
        fileNumber: string | null = null,
    ) {
        return this.http.put<Preregistration>(
            `${environment.baseApiUrl}/preregistrations/${id}/`,
            {
                first_name: firstname,
                last_name: lastname,
                email,
                phone_number: phoneNumber,
                session_id: sessionId,
                exam_id: examId,
                file_number: fileNumber,
            }
        ).pipe(map((res) => new Preregistration(res)));
    }

    partialUpdate (id: number | string, data) {
        return this.http.patch<Preregistration>(
            `${environment.baseApiUrl}/preregistrations/${id}/`,
            data
        ).pipe(map((res) => new Preregistration(res)));
    }

    sendConvocation (preregistrationId: number) {
        return this.http.post(
            `${environment.baseApiUrl}/preregistrations/${preregistrationId}/send_convocation/`,
            {}
        );
    }

    delete (id: number | string) {
        return this.http.delete(`${environment.baseApiUrl}/preregistrations/${id}/`);
    }

    
}
