<div class="row">

</div>
<div class="row mb-5" *ngIf="user">
    <div class="col d-flex flex-row">
        <div>
            <i class="fas fa-user-circle" style="font-size: 4rem;"></i>
        </div>
        <div class="ml-3 d-flex flex-column">
            <b>{{ user.first_name }} {{ user.last_name }}</b>
            <span>{{ user.student_profile?.address }}</span>
            <span>{{ user.email }}</span>
            <span>
                {{ user.phone_number }}
            </span>
            <span>Né(e) le {{ user.student_profile?.birthday_date | date:'d/M/yyyy' }}</span>
            <span>Sexe: {{ user.student_profile?.sex == 'M' ? 'Homme' : (user.student_profile?.sex == 'F' ? 'Femme' :
                'Inconnu') }}</span>
            <span>Adresse: {{ user.student_profile.address }} {{ user.student_profile.zipcode }} {{
                user.student_profile.city }}</span>
            <div class="form-check mt-2">
                <input type="checkbox" class="form-check-input" id="wantsToBePublishedInput"
                    (ngModelChange)="onModelChange($event)" [ngModel]="user.student_profile?.wants_to_be_published">
                <label class="form-check-label" for="wantsToBePublishedInput">Apparaître dans le registre des
                    professionnels certifiés</label><br>
                <small *ngIf="profileUpdated" class="text-success"><i class="fas fa-check"></i> Préférences mises à
                    jour</small>
                <small *ngIf="profileUpdateError" class="text-danger">Erreur lors de la mise à jour des
                    préférences</small>
            </div>
            <div>
                <button class="btn btn-link pl-0" (click)="openUpdateProfilModal()">
                    Modifier mon profil
                </button>
            </div>

        </div>
    </div>
</div>
<div class="row">
    <div class="col">
        <h5>Examens</h5>
    </div>
</div>
<app-loading-overlay [active]="loading">
    <table class="table table-striped">
        <thead>
            <tr>
                <th scope="col">Code</th>
                <th scope="col">Date</th>
                <th scope="col">Certification</th>
                <th scope="col">Session</th>
                <th scope="col">Confirmation de participation</th>
                <th scope="col">Résultat</th>
                <th scope="col">Diplôme</th>
                <th scope="col">Examens blancs</th>
                <th scope="col">Examens officiels</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let exam of exams; index as i">
                <td>
                    {{ exam.voucher_code }}
                </td>
                <td>
                    <ng-container *ngIf="!exam.session">
                        <span class="text-secondary">
                            En attente d'attribution
                        </span>
                    </ng-container>
                    <ng-container *ngIf="exam.session">{{ exam.session.date | date:'dd/MM/yyyy' }}</ng-container>
                </td>
                <td>
                    <ng-container *ngIf="!exam.certification">
                        <span class="text-secondary">
                            En attente d'attribution
                        </span>
                    </ng-container>
                    <ng-container *ngIf="exam.certification">{{ exam.certification.title }}</ng-container>
                </td>
                <td>
                    <ng-container *ngIf="!exam.session">
                        <span class="text-secondary">
                            En attente d'attribution
                        </span>
                    </ng-container>
                    <ng-container *ngIf="exam.session">
                        <span class="btn btn-link" (click)="openSessionModal(exam.session)">
                            {{ exam.session.code }}
                        </span>
                    </ng-container>
                </td>
                <td>
                    <ng-container *ngIf="exam.session != null && exam.studentConfirmedAt == null">
                        <button class="btn btn-link" (click)="confirmStudentAttendance(exam)">
                            Confirmer ma participation
                        </button>
                    </ng-container>
                    <ng-container *ngIf="exam.studentConfirmedAt != null">
                        <span class="text-success">
                            Participation confirmée
                        </span>
                    </ng-container>
                </td>
                <td>
                    <a *ngIf="exam.success == true && exam.locked == true" class="text-success" style="cursor: pointer;"
                        (click)="openExamResultsModal(exam)">
                        <i class="fas fa-check"></i>
                        Examen réussi
                        <i class="fas fa-external-link-square-alt"></i>
                    </a>
                    <a *ngIf="exam.success == false && exam.locked == true" class="text-danger" style="cursor: pointer;"
                        (click)="openExamResultsModal(exam)">
                        <i class="fas fa-times"></i>
                        Examen échoué
                        <i class="fas fa-eye"></i>
                    </a>
                    <span *ngIf="exam.locked != true || (exam.success != false && exam.success != true)"><span
                            class="text-secondary">Non disponible</span></span>
                </td>
                <td>
                    <ng-container *ngIf="!exam.diplome">
                        <span class="text-secondary">Non disponible</span>
                    </ng-container>
                    <a *ngIf="exam.diplome" [href]="exam.diplome">Voir le diplôme</a>
                </td>
                <td *ngIf="exam.certification">
                    <div *ngFor="let test of exam.certification.mock_tests">
                        <button class="btn btn-link" [routerLink]="['/dashboard', 'exam_mock_test', exam.id, test.id]">
                            <i class="fas fa-play"></i>&nbsp;Démarrer {{ test.label }}
                        </button>
                    </div>
                </td>
                <td *ngIf="!exam.certification"></td>
                <td *ngIf="exam.certification">
                    <div *ngFor="let test of exam.certification.tests" style="display: flex;">
                        <span style="margin-right: 6px;">{{ test.label }} :</span>

                        <ng-container *ngIf="testStartedAt(test)">
                            <span class="text-secondary" style="margin-right: 6px; white-space: nowrap;">
                                Lancé le {{ testStartedAt(test) | date:'dd/MM/yyyy à HH:mm' }}
                            </span>
                        </ng-container>

                        <ng-container *ngIf="!testDone(exam, test)">
                            <ng-container *ngIf="!exam.session_not_open_yet && !exam.session_already_closed">
                                <button class="btn btn-link" style="white-space: nowrap;"
                                    [routerLink]="['/dashboard', 'exam_test', exam.id, test.id]">
                                    <i class="fas fa-play"></i>&nbsp;
                                    <span *ngIf="testStartedAt(test) != null">Relancer</span>
                                    <span *ngIf="testStartedAt(test) == null">Démarrer</span>
                                </button>
                            </ng-container>

                            <ng-container *ngIf="exam.session_not_open_yet">
                                <span class="text-secondary">Indisponible</span>
                            </ng-container>

                            <ng-container *ngIf="exam.session_already_closed">
                                <span class="text-secondary">Expiré</span>
                            </ng-container>
                        </ng-container>

                        <ng-container *ngIf="testDone(exam, test)">
                            <span class="text-secondary">Terminé</span>
                        </ng-container>
                    </div>
                </td>
                <td *ngIf="!exam.certification"></td>
            </tr>
        </tbody>
    </table>
</app-loading-overlay>