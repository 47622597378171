import {Component, OnInit, Input} from "@angular/core";
import {FormGroup, FormControl} from "@angular/forms";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {ExamService} from "../../../shared/services/exam/exam.service";
import {take} from "rxjs/operators";
import {Exam} from "../../../shared/models/exam";
import {ShortExamSession} from "src/app/shared/models/examSession";
import {Certification} from "src/app/shared/models/certification";
import {ExamSessionService} from "src/app/shared/services/exam-session/exam-session.service";
import {CertificationService} from "src/app/shared/services/certification/certification.service";
import {forkJoin} from "rxjs";

@Component({
    selector: "app-exam-edition-modal",
    templateUrl: "./exam-edition-modal.component.html",
    styleUrls: ["./exam-edition-modal.component.scss"]
})
export class ExamEditionModalComponent implements OnInit {
    @Input() exam: Exam;

    examForm: FormGroup;
    isDataLoading: boolean = true;
    error: string;
    submitting: boolean = false;
    validationErrors: Record<string, string>;
    sessions: Array<ShortExamSession>;
    certifications: Array<Certification>;

    constructor (
        public activeModal: NgbActiveModal,
        public examService: ExamService,
        public examSessionService: ExamSessionService,
        public certificationService: CertificationService
    ) {}

    ngOnInit () {
        // Load data

        const getSessions = this.examSessionService.shortList();
        const getCertifications = this.certificationService.list(true);

        forkJoin([
            getSessions,
            getCertifications
        ]).pipe(take(1)).
            subscribe(([
                sessions,
                certifications
            ]) => {
                this.sessions = sessions;
                this.certifications = certifications;
                this.resetForm();
                this.isDataLoading = false;
            });

    }

    private resetForm () {
        this.examForm = new FormGroup({
            fileNumber: new FormControl(this.exam.file_number),
            certificationId: new FormControl(this.exam.certification
                ? this.exam.certification.id
                : null),
            sessionId: new FormControl(this.exam.session
                ? this.exam.session.id
                : null)
        });
    }

    validate () {
        this.submitting = true;

        this.examService.partialUpdate(
            this.exam.id,
            {
                // eslint-disable-next-line camelcase
                file_number: this.examForm.value.fileNumber,

                // eslint-disable-next-line camelcase
                certification_id: this.examForm.value.certificationId,

                // eslint-disable-next-line camelcase
                session_id: this.examForm.value.sessionId

            }
        ).pipe(take(1)).
            subscribe(
                (res: Exam) => {
                    this.activeModal.close(res);
                },
                (err) => {
                    if (err.status === 400) {
                        this.validationErrors = err.error;
                    } else {
                        this.error = "Une erreur est survenue lors de la création des codes";
                        throw err;
                    }
                    this.submitting = false;
                }
            );
    }

}
