import {Component, OnInit} from "@angular/core";
import {FormGroup, FormControl, Validators} from "@angular/forms";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {ExamService} from "../../../shared/services/exam/exam.service";
import {take} from "rxjs/operators";
import {Exam} from "../../../shared/models/exam";
import {InvoiceService} from "../../../shared/services/invoice/invoice.service";
import {Invoice} from "../../../shared/models/invoice";
import {forkJoin} from "rxjs";
import {ExamCenterService} from "../../../shared/services/exam-center/exam-center.service";
import {ExamCenter} from "../../../shared/models/examCenter";

@Component({
    selector: "app-exam-modal",
    templateUrl: "./exam-modal.component.html",
    styleUrls: ["./exam-modal.component.scss"]
})
export class ExamModalComponent implements OnInit {
  examForm: FormGroup;
  invoices: Array<Invoice>;
  examCenters: Array<ExamCenter>;
  isDataLoading: boolean = true;
  error: string;
  submitting: boolean = false;

  constructor (
    public activeModal: NgbActiveModal,
    public invoiceService: InvoiceService,
    public examCenterService: ExamCenterService,
    public examService: ExamService
  ) {
      this.examForm = new FormGroup({
          count: new FormControl(1),
          invoice: new FormControl(
              "",
              Validators.required
          ),
          examCenter: new FormControl(
              "",
              Validators.required
          )
      });
      this.isDataLoading = true;
  }

  ngOnInit () {
      const getExamCenters = this.examCenterService.list(true, true);
      const getInvoices = this.invoiceService.list(true);

      // Fill user form once all data has been fetched
      forkJoin(
          getExamCenters,
          getInvoices
      ).pipe(take(1)).
          subscribe(([
              examCenters,
              invoices
          ]) => {
              this.examCenters = examCenters;
              this.invoices = invoices;
              this.isDataLoading = false;
          });
  }

  validate () {
      this.submitting = true;

      const {count} = this.examForm.value;
      const invoice = this.invoices.find((i: Invoice) => i.id == this.examForm.value.invoice);
      const examCenter = this.examCenters.find((e: ExamCenter) => e.id == this.examForm.value.examCenter);

      this.examService.generate(
          count,
          invoice,
          examCenter
      ).pipe(take(1)).
          subscribe(
              (res: Array<Exam>) => {
                  this.activeModal.close(res);
              },
              (err: any) => {
                  this.error = "Une erreur est survenue lors de la création des codes";
                  this.submitting = false;
              }
          );
  }

}
