/* eslint-disable @typescript-eslint/no-explicit-any */
import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {BehaviorSubject, Observable, throwError} from "rxjs";
import {SignupDTO} from "../../dto/signupDTO";
import {ChangePasswordDTO} from "../../dto/changePasswordDTO";
import {ResetPasswordDTO} from "../../dto/resetPasswordDTO";
import {catchError, map} from "rxjs/operators";


@Injectable({
    "providedIn": "root"
})

export class AuthenticationService {

    private isLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor (private http: HttpClient) {

        // Instanciate isLoggedIn depending on if there's a stored token or not
        if (this.getAccessToken()) {
            this.isLoggedIn.next(true);
        }
    }

    public login (email, password): Promise<any> {
        return this.http.post<any>(
            `${environment.baseApiUrl}/auth/token/`,
            {
                email,
                password
            }
        ).toPromise().
            then((res) => {
                this.setTokens(
                    res.access,
                    res.refresh
                );
                window.location.href = "/dashboard";
                this.isLoggedIn.next(true);
            });
    }

    public logout (): void {
        this.clearTokens();
        this.isLoggedIn.next(false);
    }

    public signup (signupDTO: SignupDTO): Observable<any> {
        return this.http.post<any>(
            `${environment.baseApiUrl}/auth/signup/`,
            signupDTO
        );
    }

    // eslint-disable-next-line class-methods-use-this
    public getAccessToken (): string {
        return localStorage.getItem("access");
    }

    // eslint-disable-next-line class-methods-use-this
    public getRefreshToken (): string {
        return localStorage.getItem("refresh");
    }

    // eslint-disable-next-line class-methods-use-this
    private setAccessToken (accessToken: string): void {
        localStorage.setItem(
            "access",
            accessToken
        );
    }

    // eslint-disable-next-line class-methods-use-this
    private setTokens (accessToken: string, refreshToken: string): void {
        localStorage.setItem(
            "access",
            accessToken
        );

        localStorage.setItem(
            "refresh",
            refreshToken
        );
    }

    // eslint-disable-next-line class-methods-use-this
    private clearTokens (): void {
        localStorage.removeItem("access");
        localStorage.removeItem("refresh");
    }

    public getIsLoggedInSubject (): BehaviorSubject<boolean> {
        return this.isLoggedIn;
    }

    public changePassword (changePasswordDTO: ChangePasswordDTO): Observable<void> {
        return this.http.post<any>(
            `${environment.baseApiUrl}/auth/change_password/`,
            changePasswordDTO
        );
    }

    public resetPassword (resetPasswordDTO: ResetPasswordDTO): Observable<void> {
        return this.http.post<any>(
            `${environment.baseApiUrl}/auth/reset_password/`,
            resetPasswordDTO
        );
    }

    public refreshToken (): Observable<string | null> {
        // Récupérer le token de rafraîchissement
        const refreshToken = this.getRefreshToken();

        if (!refreshToken) {

            // No refresh token in storage. Return an observable that emits null.

            return new Observable((observer) => {
                observer.next(null);
                observer.complete();
            });
        }

        // Effectuer une requête vers votre backend pour rafraîchir le token d'accès
        return this.http.post<any>(
            `${environment.baseApiUrl}/auth/token/refresh/`,
            {
                refresh: refreshToken
            }
        ).
            pipe(
                map((response: any) => {
                    // Supposons que votre backend renvoie le nouveau token d'accès
                    const newAccessToken = response.access;
                    if (newAccessToken) {
                        // Stocker le nouveau token d'accès
                        this.setAccessToken(newAccessToken);
                        return newAccessToken;
                    }

                    // Gérer les erreurs si aucun token n'est reçu
                    return throwError(new Error("No access token received"));
                }),
                catchError((error) => throwError(error))
            );
    }

    public generateExternalApiKey (): Observable<string> {
        return this.http.get<any>(
            `${environment.baseApiUrl}/auth/generate_external_api_key/`
        )
    }
}
