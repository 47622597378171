<div class="modal-header">
    <h4 class="modal-title">Confirmer ma participation</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="form-group row">
        <input type="checkbox" class="col-1 form-control" [checked]="accepted" (change)="accepted = !accepted"
            style="margin-top: 15px;" />
        <label class="col col-form-label">
            <p>
                Je confirme ma participation à l'examen {{ exam.certification.title }}.
            </p>

            <p *ngIf="exam.session.zoomLink != null">
                L'examen est programmé le {{ exam.session.date| date:'dd/MM/yyyy' }} à {{ exam.session.date|
                date:'HH:mm' }} en ligne avec la connexion Zoom suivante :
            </p>

            <p *ngIf="exam.session.zoomLink != null" style="text-align: center;">
                <a href="{{ exam.session.zoomLink }}" target="_blank">{{ exam.session.zoomLink }}</a>
            </p>

            <p *ngIf="exam.session.zoomLink == null">
                L'examen est programmé le {{ exam.session.date| date:'dd/MM/yyyy' }} à {{ exam.session.date|date:'HH:mm'
                }} au centre d'examen {{ exam.session.center.nom }} à l'adresse suivante :
            </p>

            <p *ngIf="exam.session.zoomLink == null" style="text-align: center;">
                {{ exam.session.center.adresse1 }}<br>
                <span *ngIf="exam.session.center.adresse2">{{ exam.session.center.adresse2 }}<br></span>
                {{ exam.session.center.zipcode }} {{ exam.session.center.city }}<br>
                {{ exam.session.examLocationDetails }}
            </p>

            <p *ngIf="exam.session.info">
                {{ exam.session.info }}
            </p>

            <p>
                J'ai pris connaissance des conditions d'examen et m'engage à participer à cette session officielle
                d'examen
                national enregistré sous la référence {{ exam.voucher_code }} auprès de France Compétences.
            </p>
        </label>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-danger" (click)="activeModal.dismiss()">Annuler</button>
    <button type="button" class="btn btn-outline-success" (click)="validate()" [disabled]="!accepted">
        <span *ngIf="submitting" class="button-loader"></span>
        Valider
    </button>
</div>