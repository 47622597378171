<div class="modal-header">
    <h4 class="modal-title">Historique du ticket {{ exam.voucher_code }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div>
        Création : {{ exam.created_at | date:'dd/MM/yyyy' }}<br>
        Assignation à une pré-inscription : {{ exam.assignedToPreregistrationAt | date:'dd/MM/yyyy'
        }}<br>
        Envoi de la convocation : {{ exam.convocation_sent_at | date:'dd/MM/yyyy' }}<br>
        Validation de la participation le : {{ exam.studentConfirmedAt | date:'dd/MM/yyyy' }}<br>
        Consommé le : {{ exam.usedAt | date:'dd/MM/yyyy' }}
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-success" (click)="activeModal.dismiss()">Ok</button>
</div>