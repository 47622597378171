import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from '@angular/forms';

@Directive({
  selector: '[appPhoneValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: PhoneValidatorDirective, multi: true }]
})
export class PhoneValidatorDirective implements Validator {
  @Input('appPhoneValidator') phonePattern: string;

  validate(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null; // Si le champ est vide, ne pas appliquer la validation
    }

    // Numéros locaux (commencent par 0, groupes optionnels séparés par des espaces)
    const localPattern = /^0\d(?: ?\d{2,4}){1,4}$/;

    // Numéros internationaux (commencent par +, suivis d'un indicatif et de groupes de chiffres)
    const internationalPattern = /^\+\d{1,3}(?: ?\d{1,4}){1,5}$/;

    // Combiner les deux patterns (local OU international)
    const phoneRegExp: RegExp = new RegExp(
      this.phonePattern || `${localPattern.source}|${internationalPattern.source}`
    );

    // Valider le numéro
    if (!phoneRegExp.test(control.value)) {
      return { invalidFormat: true }; // Retourner une erreur si le format est incorrect
    }

    return null; // Retourner null si le numéro de téléphone est valide
  }
}
