import { Component } from '@angular/core';
import {AuthenticationService} from "../../shared/services/authentication/authentication.service";

@Component({
  selector: 'app-api',
  templateUrl: './api.component.html',
  styleUrls: ['./api.component.scss']
})
export class ApiComponent {
  apiKey: string | null = null; // Contient la clé API générée
  loading: boolean = false; // Indique si la clé est en cours de génération
  errorMessage: string | null = null; // Message d'erreur en cas d'échec

  constructor(private authenticationService: AuthenticationService) {}

  generateApiKey(): void {
    this.loading = true;
    this.errorMessage = null;
    
    this.authenticationService.generateExternalApiKey().subscribe(
      (res) => {
        this.apiKey = res;
        this.loading = false;
      },
      (error) => {
        this.errorMessage = error.error.detail;
        this.loading = false;
      }
    );
  }
}
