<div class="modal-header">
    <h4 *ngIf="!membership" class="modal-title">Ajouter une adhésion</h4>
    <h4 *ngIf="membership" class="modal-title">Adhésion {{membership.reference}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="membershipForm" *ngIf="!isDataLoading">
        <div class="form-group row">
            <label for="referenceInput" class="col-sm-4 col-form-label text-right">Référence</label>
            <div class="col-sm-8">
                <input type="text" class="form-control" id="referenceInput" formControlName='reference'>
            </div>
        </div>
        <div class="form-group row">
            <label for="centerSelect" class="col-sm-4 col-form-label text-right">Centre d'examen adhérant</label>
            <div class="col-sm-8">
                <select class="form-control" formControlName="center" id="centerSelect">
                    <option *ngFor="let center of centers; let i = index" [value]='centers[i].id'>
                        {{centers[i].nom}}
                    </option>
                </select>
            </div>
        </div>
        <div class="form-group row">
            <label for="dateStartInput" class="col-sm-4 col-form-label text-right">Date de début</label>
            <div class="input-group col-sm-8">
                <input class="form-control" id="dateStartInput" placeholder="jj/mm/aaaa" formControlName='date_start'
                    name="dp1" ngbDatepicker #d1="ngbDatepicker">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()" type="button"><i
                            class="far fa-calendar-alt"></i></button>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label for="dateEndInput" class="col-sm-4 col-form-label text-right">Date de fin</label>
            <div class="input-group col-sm-8">
                <input class="form-control" id="dateEndInput" placeholder="jj/mm/aaaa" formControlName='date_end'
                    name="dp2" ngbDatepicker #d2="ngbDatepicker">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d2.toggle()" type="button"><i
                            class="far fa-calendar-alt"></i></button>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label for="sessionsOnSiteInput" class="col-sm-4 col-form-label text-right">Sessions sur site</label>
            <div class="input-group col-sm-1">
                <input type="checkbox" class="form-control" id="sessionsOnSiteInput" formControlName="sessions_on_site">
            </div>
        </div>
        <div class="form-group row">
            <label for="sessionsOnlineInput" class="col-sm-4 col-form-label text-right">Sessions en ligne</label>
            <div class="input-group col-sm-1">
                <input type="checkbox" class="form-control" id="sessionsOnlineInput" formControlName="remote_sessions">
            </div>
        </div>
    </form>
    <div *ngIf="error" class="alert alert-danger">
        {{error}}
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-danger" (click)="activeModal.dismiss()">Annuler</button>
    <button type="button" class="btn btn-outline-success" (click)="validate()">
        <span *ngIf="submitting" class="button-loader"></span>
        Valider
    </button>
</div>