import {Component, OnInit} from "@angular/core";
import {ExamSession} from "../../../shared/models/examSession";
import {ExamSessionService} from "../../../shared/services/exam-session/exam-session.service";
import {FormControl} from "@angular/forms";
import {take} from "rxjs/internal/operators/take";
import {debounceTime} from "rxjs/operators";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {SessionModalComponent} from "../session-modal/session-modal.component";
import {SessionExportModalComponent} from "../session-export-modal/session-export-modal.component";
import {User} from "../../../shared/models/user";
import {UserService} from "../../../shared/services/user/user.service";
import {environment} from "../../../../environments/environment";
import {SessionSupervisionModalComponent} from "../session-supervision-modal/session-supervision-modal.component";
import {SupervisorAttendanceConfirmationModalComponent} from "../supervisor-attendance-confirmation-modal/supervisor-attendance-confirmation-modal.component";
import {CorrectorAttendanceConfirmationModalComponent} from "../corrector-attendance-confirmation-modal/corrector-attendance-confirmation-modal.component";
import {SessionCommentsModalComponent} from "../session-comments-modal/session-comments-modal.component";
import {FileDownloadHelper} from "../../../shared/helpers/file-download.helper";
import {ToastrService} from "ngx-toastr";
import {HttpResponse} from "@angular/common/http";
import { Router } from '@angular/router';
import {SessionFilterEnum} from "../../../shared/enums/sessionFilterEnum";


@Component({
    selector: "app-session-list",
    templateUrl: "./session-list.component.html",
    styleUrls: ["./session-list.component.scss"]
})
export class SessionListComponent implements OnInit {

    examSessions: Array<ExamSession>; // List of all the exams fetched from backend
    searchTermControl: FormControl;
    filterControl: FormControl;
    user: User;
    loading: boolean;

    // Paginiation related
    page: number;
    pageSize: number;
    itemsCount: number;

    constructor (
        private examSessionService: ExamSessionService,
        private modalService: NgbModal,
        private userService: UserService,
        private toastr: ToastrService,
        private router: Router,
    ) {
        this.searchTermControl = new FormControl("");
        this.page = 1;
        this.pageSize = environment.pageSize;
        this.loading = false;
        this.filterControl = new FormControl(SessionFilterEnum.ALL);
    }

    ngOnInit () {

        // Subscribe to the change of value of the search field
        this.searchTermControl.valueChanges.pipe(debounceTime(1000)).
            subscribe(() => {
                this.fetchItemCount();
                this.fetchCurrentPage();
            });

        // Subscribe to the change of value of the filter
        this.filterControl.valueChanges.
            subscribe(() => {
                this.fetchItemCount();
                this.fetchCurrentPage();
            });


        this.fetchCurrentPage();
        this.fetchItemCount();
        this.userService.getMe().pipe(take(1)).
            subscribe((user: User) => {
                this.user = user;
            });
    }

    fetchCurrentPage () {
        this.loading = true;

        const searchTerm = this.searchTermControl.value;
        const filter = this.filterControl.value;

        this.examSessionService.list(
            this.page,
            searchTerm,
            [filter]
        ).pipe(take(1)).
            subscribe((res: Array<ExamSession>) => {
                this.examSessions = res;
                this.loading = false;
            });
    }


    fetchItemCount () {
        const searchTerm = this.searchTermControl.value;
        const filter = this.filterControl.value;

        this.examSessionService.count(
            searchTerm,
            [filter]
        ).pipe(take(1)).
            subscribe((count: number) => {
                this.itemsCount = count;
            });
    }

    onPageChange (newPage: number) {
        this.page = newPage;
        this.fetchCurrentPage();
    }

    add () {
        const modalRef = this.modalService.open(
            SessionModalComponent,
            {size: "lg"}
        );
        modalRef.componentInstance.examSession = null;
        modalRef.result.then(() => {
            this.fetchCurrentPage();
        }).catch(() => {
        // Dummy catch tu prevent exception thrown on modal dismissal
        });
    }

    edit (examSession: ExamSession) {
        const modalRef = this.modalService.open(
            SessionModalComponent,
            {size: "lg"}
        );
        modalRef.componentInstance.examSession = examSession;
        modalRef.result.then(() => {
            this.fetchCurrentPage();
        }).catch(() => {
        // Dummy catch tu prevent exception thrown on modal dismissal
        });
    }

    supervise (examSession: ExamSession) {
        const modalRef = this.modalService.open(
            SessionSupervisionModalComponent,
            {size: "lg"}
        );
        modalRef.componentInstance.examSession = examSession;
        modalRef.result.then(() => {
            this.fetchCurrentPage();
        }).catch(() => {
        // Dummy catch tu prevent exception thrown on modal dismissal
        });
    }

    delete (examSession: ExamSession) {
        const res = confirm("Êtes-vous sûr de vouloir supprimer ce fichier ?");
        if (res) {
            this.examSessionService.destroy(examSession.id).pipe(take(1)).
                subscribe(() => {
                    this.fetchCurrentPage();
                });
        }
    }

    confirmSupervisorAttendance (examSession: ExamSession) {
        const modalRef = this.modalService.open(
            SupervisorAttendanceConfirmationModalComponent,
            {size: "lg"}
        );
        modalRef.componentInstance.examSession = examSession;
        modalRef.result.then(() => {
            this.fetchCurrentPage();
        }).catch(() => {
        // Dummy catch tu prevent exception thrown on modal dismissal
        });
    }

    confirmCorrectorAttendance (examSession: ExamSession) {
        const modalRef = this.modalService.open(
            CorrectorAttendanceConfirmationModalComponent,
            {size: "lg"}
        );
        modalRef.componentInstance.examSession = examSession;
        modalRef.result.then(() => {
            this.fetchCurrentPage();
        }).catch(() => {
        // Dummy catch tu prevent exception thrown on modal dismissal
        });
    }

    nbLockedExams (examSession: ExamSession) {
        return examSession.exams.filter((exam) => exam.locked).length;
    }

    downloadXml (examSession: ExamSession) {

        const modalRef = this.modalService.open(
            SessionExportModalComponent,
            {size: "lg"}
        );
        modalRef.componentInstance.examSession = examSession;
    }

    minutePdf (examSession: ExamSession) {
        this.examSessionService.minutePdf(examSession.id).pipe(take(1)).
            subscribe(
                (response: HttpResponse<Blob>) => {
                    FileDownloadHelper.downloadBlobFromResponse(
                        response,
                        `PV_${examSession.code}.pdf`
                    );
                },
                () => {
                    this.toastr.error(
                        "Contactez l'administrateur pour plus d'informations",
                        "Erreur"
                    );
                }
            );
    }

    openSessionCommentsModal (session: ExamSession) {

        const modalRef = this.modalService.open(
            SessionCommentsModalComponent,
            {"size": "lg"}
        );
        modalRef.componentInstance.session = session;

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        modalRef.result.then((res: ExamSession) => {

            this.fetchCurrentPage();

        }).catch(() => {
            // Dummy catch to avoid ugly errors in console
        });

    }

    // Méthode pour rediriger vers la liste des préinscriptions
    goToPreregistrations(session: ExamSession) {
        this.router.navigate(['/dashboard/preregistrations'], {
            queryParams: { session_id: session.id }
        });
    }
}
