import {Component, OnInit} from "@angular/core";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {ActivatedRoute} from "@angular/router";
import {take} from "rxjs/operators";
import {CertificationTest} from "src/app/shared/models/certificationTest";
import {Exam} from "../../shared/models/exam";
import {ExamService} from "../../shared/services/exam/exam.service";
import {environment} from "../../../environments/environment";

@Component({
    "selector": "app-exam-test",
    "templateUrl": "./exam-test.component.html",
    "styleUrls": ["./exam-test.component.scss"]
})
export class ExamTestComponent implements OnInit {

    exam: Exam;
    test: CertificationTest;
    embeddingQcmURL: SafeResourceUrl;
    document: string;
    error: string;
    baseUrl = environment.baseUrl;

    constructor (
        private examService: ExamService,
        private route: ActivatedRoute,
        private sanitizer: DomSanitizer
    ) { }

    ngOnInit (): void {
        window.setTimeout(
            () => {
                this.checkIfExamCanBePassed();
            },
            2000
        );
    }

    private checkIfExamCanBePassed () {
        const examId = this.route.snapshot.paramMap.get("exam_id");

        // Fetch exam
        this.examService.retrieve(examId).pipe(take(1)).
            subscribe((exam: Exam) => {
                this.exam = exam;

                // Fetch test
                const testId = parseInt(this.route.snapshot.paramMap.get("test_id"));
                this.test = this.exam.certification.tests.find((test) => test.id == testId);

                // Check that exam is open today
                
                if (this.exam.session_not_open_yet) {
                    this.error = "Cette session d'examen n'est pas encore ouverte";
                    return;
                }

                // Check that exam is not closed

                if (this.exam.session_already_closed) {
                    this.error = "Cette session d'examen est déjà fermée";
                    return;
                }

                // Check that test has a document or an embedded test
                if (!this.test.type) {
                    this.error = "Cette épreuve n'a pas encore de sujet associé";
                    return;
                }

                // Fetch exam result value if any
                const examResultValue = this.exam.examResultValues.find((exam_result_value) => exam_result_value.test.id == testId);


                // Check that test is not already done
                if (examResultValue && examResultValue.is_done) {
                    this.error = "Cette épreuve a déjà été passée";
                    return;
                }

                // Check if exam can pass
                if (!this.exam.can_pass) {
                    window.setTimeout(
                        () => {
                            this.checkIfExamCanBePassed();
                        },
                        10000
                    );
                    return;
                }

                // If we can pass the QCM, then stop the interval and fetch the QCM embedding code or the document

                this.examService.startTest(
                    this.exam.id,
                    this.test.id,
                    this.test.type == "embedded"
                        ? "text"
                        : "blob"
                ).pipe(take(1)).
                    subscribe(
                        (res: string | Blob) => {

                            if (res instanceof Blob) {
                                this.embeddingQcmURL = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(res));

                            } else {
                                this.embeddingQcmURL = this.sanitizer.bypassSecurityTrustResourceUrl(res);
                            }

                            window.setTimeout(
                                () => {
                                    this.checkIfExamCanStillBePassed();
                                },
                                10000
                            );

                        },
                        (error) => {
                            this.error = error.error;
                            throw error;
                        }
                    );


            });
    }


    private checkIfExamCanStillBePassed () {
        const examId = this.route.snapshot.paramMap.get("exam_id");

        // Fetch exam
        this.examService.retrieve(examId).pipe(take(1)).
            subscribe(
                (exam: Exam) => {

                    if (exam.can_pass) {
                        window.setTimeout(
                            () => {
                                this.checkIfExamCanStillBePassed();
                            },
                            // Every 1 minute
                            60 * 1000
                        );

                    } else {
                        this.error = "Il semblerait que votre accès ai été révoqué.";
                    }
                },
                (error) => {
                    window.setTimeout(
                        () => {
                            this.checkIfExamCanStillBePassed();
                        },
                        // Every 1 minute
                        60 * 1000
                    );
                }
            );
    }
}
