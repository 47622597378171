import { ExamCenter } from './examCenter';

export class ExamCenterMemberShip {
    id: number;
    center: ExamCenter;
    date_start: Date;
    date_end: Date;
    reference: String;
    sessions_on_site: boolean;
    remote_sessions: boolean;

    constructor(data:any) {
        if(data) {
            this.id = data.id;
            if (data.center) this.center = new ExamCenter(data.center);
            this.date_start = new Date(data.date_start);
            this.date_end = new Date(data.date_end);
            this.reference = data.reference;
            this.sessions_on_site = data.sessions_on_site;
            this.remote_sessions = data.remote_sessions;
        }
    }

    /* Check if the membership is valid as of now */
    get isValid(): boolean {
        let now = new Date();
        return now > this.date_start && now < this.date_end;
    }
}