import {Component, OnInit, Input} from "@angular/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {ExamSession, ExamSessionCreateOrUpdateData} from "../../../shared/models/examSession";
import {FormGroup, FormControl} from "@angular/forms";
import {ExamCenter} from "../../../shared/models/examCenter";
import {User} from "../../../shared/models/user";
import {ExamCenterService} from "../../../shared/services/exam-center/exam-center.service";
import {take} from "rxjs/operators";
import {UserService} from "../../../shared/services/user/user.service";
import {GroupsEnum} from "../../../shared/enums/groupsEnum";
import {Group} from "../../../shared/models/group";
import {forkJoin} from "rxjs";
import {ExamSessionService} from "../../../shared/services/exam-session/exam-session.service";
import {environment} from "../../../../environments/environment";
import {ToastrService} from "ngx-toastr";

@Component({
    selector: "app-session-modal",
    templateUrl: "./session-modal.component.html",
    styleUrls: ["./session-modal.component.scss"]
})
export class SessionModalComponent implements OnInit {

    @Input() examSession: ExamSession;
    sessionForm: FormGroup;
    examCenters: Array<ExamCenter>;
    supervisors: Array<User>;
    correcteurs: Array<User>;
    isDataLoading: boolean = true;
    user: User;
    baseUrl = environment.baseUrl;
    submitting: boolean = false;

    constructor (
        public activeModal: NgbActiveModal,
        public examCenterService: ExamCenterService,
        public examSessionService: ExamSessionService,
        private userService: UserService,
        private toastr: ToastrService
    ) {
        this.sessionForm = new FormGroup({
            code: new FormControl(""),
            date: new FormControl(""),
            time:  new FormControl(""),
            center: new FormControl(""),
            supervisor: new FormControl(""),
            correcteur: new FormControl(""),
            correcteur2: new FormControl(""),
            zoomLink: new FormControl(""),
            examLocationDetails: new FormControl(""),
            info: new FormControl(""),
            notifyUsers: new FormControl(true),
            remote: new FormControl(false)
        });
        this.isDataLoading = true;
    }

    /*
     * Function to instanciate the frontend form
     * Call only after all data is fetched
     */
    private resetForm () {

        // Populate the form if there's an input
        if (this.examSession !== null) {
            this.sessionForm.setValue({
                code: this.examSession.code,
                date: new Date(this.examSession.date),
                time: this.examSession.date.substring(11, 16),
                center: this.examSession.center
                    ? this.examSession.center.id
                    : null,
                supervisor: this.examSession.supervisor
                    ? this.examSession.supervisor.id
                    : null,
                correcteur: this.examSession.correcteur
                    ? this.examSession.correcteur.id
                    : null,
                correcteur2: this.examSession.correcteur2
                    ? this.examSession.correcteur2.id
                    : null,
                zoomLink: this.examSession.zoomLink ? this.examSession.zoomLink : "",
                examLocationDetails: this.examSession.examLocationDetails ? this.examSession.examLocationDetails : "",
                info: this.examSession.info ? this.examSession.info : "",
                notifyUsers: true,
                remote: this.examSession.zoomLink ? true : false
            });
        }

        // If the user is an exam center, set the exam center of the form to be the user's exam center
        if (this.user.isExamCenterBoss) {
            this.sessionForm.controls.center.setValue(this.user.examCenters[0].id);
            this.sessionForm.get("center").disable();
        }
    }

    ngOnInit () {
        const getExamCenters = this.examCenterService.list(true),
            getSupervisorsAndCorrecteurs = this.userService.list([
                GroupsEnum.CORRECTOR,
                GroupsEnum.SUPERVISOR
            ]),
            getUser = this.userService.getMe();

        // Fill user form once all data has been fetched
        forkJoin(
            getExamCenters,
            getSupervisorsAndCorrecteurs,
            getUser
        ).pipe(take(1)).
            subscribe(([
                examCenters,
                users,
                user
            ]) => {
                this.examCenters = examCenters;
                this.supervisors = users.filter((u: User) => u.groups.some((g: Group) => g.name == GroupsEnum.SUPERVISOR));
                this.correcteurs = users.filter((u: User) => u.groups.some((g: Group) => g.name == GroupsEnum.CORRECTOR));
                this.user = user;
                this.resetForm();
                this.isDataLoading = false;
            });
    }

    validate () {
        this.submitting = true;

        const date = new Date(this.sessionForm.controls.date.value);

        const isoDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().
            substring(
                0,
                10
            );

        const isoDateTime = `${isoDate} ${this.sessionForm.controls.time.value}:00`;

        const body: ExamSessionCreateOrUpdateData = {
            "code": this.sessionForm.controls.code.value,
            "date": isoDateTime,
            "center_id": this.sessionForm.controls.center.value,
            "notify_users": this.sessionForm.controls.notifyUsers.value,
            "info": this.sessionForm.controls.info.value
            
        };

        if (this.sessionForm.controls.remote.value) {
            if (!this.sessionForm.controls.zoomLink.value) {
                this.toastr.error(
                    "Vous devez renseigner un lien Zoom pour une session à distance",
                    "Erreur"
                );
                this.submitting = false;
                return;
            } else {
                body.zoom_link = this.sessionForm.controls.zoomLink.value;
                body.exam_location_details = null;
            }

        } else {
            if (!this.sessionForm.controls.center.value) {
                this.toastr.error(
                    "Vous devez renseigner un centre d'examen pour une session en présentiel",
                    "Erreur"
                );
                this.submitting = false;
                return;
            } else {
                body.exam_location_details = this.sessionForm.controls.examLocationDetails.value;
                body.zoom_link = null;
            }
        }

        if (this.sessionForm.controls.supervisor.value) {
            // eslint-disable-next-line camelcase
            body.supervisor_id = this.sessionForm.controls.supervisor.value;
        }

        if (this.sessionForm.controls.correcteur.value) {
            // eslint-disable-next-line camelcase
            body.correcteur_id = this.sessionForm.controls.correcteur.value;
        }

        if (this.sessionForm.controls.correcteur2.value) {
            // eslint-disable-next-line camelcase
            body.correcteur_2_id = this.sessionForm.controls.correcteur2.value;
        }

        // Trigger update if it's an edit
        if (this.examSession) {
            this.examSessionService.update(
                this.examSession.id,
                body
            ).pipe(take(1)).
                subscribe(
                    (res: ExamSession) => {
                        this.examSession = res;
                        this.resetForm();
                        this.activeModal.close(this.examSession);
                        this.submitting = false;
                    },
                    (err) => {
                        if (err.status === 597) {
                            this.toastr.error(
                                "Attention ! l'email de confirmation n'a pas pu être envoyé",
                                "Opération enregistrée"
                            );
                        } else {
                            this.toastr.error(
                                "Une erreur est survenue",
                                "Erreur"
                            );
                        }

                        this.submitting = false;
                        throw err;
                    }
                );

        // Trigger a save otherwise
        } else {
            this.examSessionService.create(body).pipe(take(1)).
                subscribe(
                    (res: ExamSession) => {
                        this.examSession = res;
                        this.resetForm();
                        this.activeModal.close(this.examSession);
                        this.submitting = false;
                    },
                    (err) => {
                        if (err.status === 597) {
                            this.toastr.error(
                                "Attention ! l'email de confirmation n'a pas pu être envoyé",
                                "Opération enregistrée"
                            );
                        } else {
                            this.toastr.error(
                                "Une erreur est survenue",
                                "Erreur"
                            );
                        }

                        this.submitting = false;
                        throw err;
                    }
                );
        }
    }
}
