/* eslint-disable camelcase */


import { ExamCenter } from "./examCenter";
import { User } from "./user";
import { Exam } from "./exam";
import { ExamSessionComment } from "./examSessionComment";


export interface ExamSessionCreateOrUpdateData {
    code: string;
    center_id: number;
    supervisor_id?: number;
    correcteur_id?: number;
    correcteur_2_id?: number;
    date: string;
    zoom_link?: string;
    exam_location_details?: string;
    info?: string;
    notify_users: boolean;
}


class Preregistration {
    id: number;
    firstname: string;
    lastname: string;
    email: string;
    phoneNumber: string | null;
    exam: Exam | null = null;
    convocationSentAt: Date | null = null;

    constructor(data: any) {
        if (data) {
            this.id = data.id;
            this.firstname = data.first_name;
            this.lastname = data.last_name;
            this.email = data.email;

            if (data.phone_number) {
                this.phoneNumber = data.phone_number;
            }

            if (data.exam) {
                this.exam = new Exam(data.exam);

            }

            if (data.convocation_sent_at) {
                this.convocationSentAt = new Date(data.convocation_sent_at);
            }

        }
    }
}

export class ExamSession {
    id: number;
    code: string;
    center: ExamCenter;
    supervisor: User;
    correcteur: User;
    correcteur2: User;
    supervisorConfirmed: Date;
    correctorConfirmed: Date;
    corrector2Confirmed: Date;
    exams: Array<Exam>;
    // Local date with format 2022-05-19 I pefer not to parse into a Date or it will add timezones and that's a pain in the ass
    date: string;
    startDatetime: string;
    comments: Array<ExamSessionComment>;
    preregistrations: Array<Preregistration>;
    zoomLink: string | null;
    examLocationDetails: string | null;
    info: string | null;

    constructor(data: any | ExamSession) {
        if (!data) {
            throw new Error("No data given");
        }

        if (data instanceof ExamSession) {
            this.id = data.id;
            this.code = data.code;
            this.center = data.center;
            this.supervisor = new User(data.supervisor);
            this.correcteur = new User(data.correcteur);
            this.correcteur2 = new User(data.correcteur2);
            this.supervisorConfirmed = data.supervisorConfirmed;
            this.correctorConfirmed = data.correctorConfirmed;
            this.corrector2Confirmed = data.corrector2Confirmed;
            this.exams = data.exams.map((e: Exam) => new Exam(e));
            this.date = data.date;
            this.startDatetime = data.startDatetime;
            this.zoomLink = data.zoomLink;
            this.examLocationDetails = data.examLocationDetails;
            this.info = data.info;


        } else {

            if (!("id" in data)) {
                throw new Error("Session instanciated with undefined id");
            }

            if (!("start_datetime" in data)) {
                throw new Error("Session instanciated with undefined start_datetime");
            }

            if (!("code" in data)) {
                throw new Error("Session instanciated with undefined code");
            }

            if (!("center" in data)) {
                throw new Error("Session instanciated with undefined center");
            }

            if (!("date" in data)) {
                throw new Error("Session instanciated with undefined date");
            }

            this.id = data.id;
            this.code = data.code;
            this.center = new ExamCenter(data.center);
            this.date = data.date.substring(0, 16);
            this.startDatetime = data.start_datetime;

            if (data.supervisor) {
                this.supervisor = new User(data.supervisor);
            }

            if (data.correcteur) {
                this.correcteur = new User(data.correcteur);
            }

            if (data.correcteur_2) {
                this.correcteur2 = new User(data.correcteur_2);
            }

            if (data.supervisor_confirmed) {
                this.supervisorConfirmed = new Date(data.supervisor_confirmed);
            }

            if (data.correcteur_confirmed) {
                this.correctorConfirmed = new Date(data.correcteur_confirmed);
            }

            if (data.correcteur_2_confirmed) {
                this.corrector2Confirmed = new Date(data.correcteur_2_confirmed);
            }

            if (data.exams) {
                this.exams = data.exams.map((e) => new Exam(e));
            }

            if (data.comments) {
                this.comments = data.comments.map((c: unknown) => new ExamSessionComment(c));
            }

            if (data.preregistrations) {
                this.preregistrations = data.preregistrations.map((p: unknown) => new Preregistration(p));
            }

            if (data.zoom_link) {
                this.zoomLink = data.zoom_link;
            }

            if (data.exam_location_details) {
                this.examLocationDetails = data.exam_location_details;
            }

            if (data.info) {
                this.info = data.info;
            }
        }
    }

    get nbPassedExams(): number {
        if (!this.exams) {
            return 0;
        }

        if (!this.exams.length) {
            return 0;
        }

        // Filter out exams that didn't have a result
        const passedExams = this.exams.filter((e: Exam) => e.success !== null);

        if (!passedExams.length) {
            return 0;
        }

        return passedExams.length;


    }

    get nbSuccessExams(): number {
        if (!this.exams) {
            return 0;
        }

        if (!this.exams.length) {
            return 0;
        }

        // Filter out exams that didn't have a result
        const passedExams = this.exams.filter((e: Exam) => e.success !== null);
        if (!passedExams.length) {
            return 0;
        }

        let successCount = 0;

        for (let i = 0; i < passedExams.length; i += 1) {
            if (passedExams[i].success) {
                successCount += 1;
            }
        }

        return successCount;
    }

    get successRate(): number {
        if (!this.exams) {
            return 0;
        }

        if (!this.exams.length) {
            return 0;
        }

        // Filter out exams that didn't have a result
        const passedExams = this.exams.filter((e: Exam) => e.success !== null);
        if (!passedExams.length) {
            return 0;
        }

        let successCount = 0;

        for (let i = 0; i < passedExams.length; i += 1) {
            if (passedExams[i].success) {
                successCount += 1;
            }
        }

        return successCount / passedExams.length;
    }

    get wasOpen() {
        return this.startDatetime !== null;
    }

    get isOpen() {
        return this.wasOpen && !this.isFinished;
    }

    get isFinished() {
        if (!this.startDatetime) {
            return false;
        }

        const startDate = new Date(this.startDatetime),

            // Obtenir le timestamp actuel (en millisecondes)
            currentTimeStamp = new Date().getTime(),

            // Calculer la différence entre les deux timestamps
            timeDifference = currentTimeStamp - startDate.getTime(),

            // Durée d'une journée en millisecondes (24 heures)
            oneDayInMilliseconds = 24 * 60 * 60 * 1000;

        // Vérifier si la différence est supérieure à 24 heures
        return timeDifference > oneDayInMilliseconds;
    }

    get canClose() {
        return this.isOpen && this.exams.every((e: Exam) => e.success === null && e.can_pass === false);
    }

    get nBPreinscriptions() {
        return this.preregistrations.length;
    }

    get nbSentPreinscriptions() {
        return this.preregistrations.filter((p) => p.convocationSentAt !== null).length;
    }

    toString(): string {
        return this.code;
    }
}

export class ShortExamSession {
    id: number;
    code: string;

    constructor(data: any | ExamSession) {
        if (!data) {
            throw new Error("No data given");
        }

        if (data instanceof ExamSession) {
            this.id = data.id;
            this.code = data.code;

        } else {

            if (!("id" in data)) {
                throw new Error("Session instanciated with undefined id");
            }

            if (!("code" in data)) {
                throw new Error("Session instanciated with undefined code");
            }

            this.id = data.id;
            this.code = data.code;
        }
    }

    toString(): string {
        return this.code;
    }
}
