<div class="modal-header">
    <h4 class="modal-title">Session {{ examSession.code }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="form-group row">
        <div class="col-sm-4 text-right">Code</div>
        <div class="col-sm-8">
            {{ examSession.code }}
        </div>
    </div>
    <div class="form-group row">
        <div class="col-sm-4 text-right">Date & Heure</div>
        <div class="col-sm-8">
            {{ examSession.date | date: 'dd/MM/yyyy HH:mm' }}
        </div>
    </div>
    <div class="form-group row">
        <div class="col-sm-4 text-right">Centre d'examen</div>
        <div class="col-sm-8">
            {{ examSession.center.nom }}
        </div>
    </div>
    <div *ngIf="examSession.zoomLink" class="form-group row">
        <div class="col-sm-4 text-right">Lien zoom</div>
        <div class="col-sm-8">
            {{ examSession.zoomLink }}
        </div>
    </div>

    <div *ngIf="examSession.zoomLink == null" class="form-group row">
        <div class="col-sm-4 text-right">Lieu de l'examen</div>
        <div class="col-sm-8">
            {{ examSession.examLocationDetails }}
        </div>
    </div>

    <div class="form-group row">
        <div class="col-sm-4 text-right">Informations</div>
        <div class="col-sm-8">
            {{ examSession.info }}
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="activeModal.dismiss()">Ok</button>
</div>