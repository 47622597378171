<nav class="navbar navbar-expand-lg navbar-light bg-light border-bottom">
    <div class="navbar-nav" *ngIf="user">
        <a routerLink="student_profile" routerLinkActive="active" class="nav-item nav-link"
            *ngIf="user.isStudent">Accueil</a>

        <a routerLink="certifications" routerLinkActive="active" class="nav-link" *ngIf="user.isStaff">
            Certifications</a>

        <a routerLink="tickets" routerLinkActive="active" class="nav-item nav-link"
            *ngIf="user.isStaff || user.isExamCenterBoss || user.isSupervisor || user.isCorrector">
            <span *ngIf="!user.isCorrector">Tickets</span>
            <!--For the corrector, we give it a slightly different name-->
            <span *ngIf="user.isCorrector">Corrections</span>
        </a>

        <a routerLink="sessions" routerLinkActive="active" class="nav-item nav-link"
            *ngIf="user.isStaff || user.isExamCenterBoss || user.isSupervisor || user.isCorrector">
            Sessions</a>

        <a routerLink="preregistrations" routerLinkActive="active" class="nav-item nav-link"
            *ngIf="user.isStaff || user.isExamCenterBoss">
            Pré-inscriptions</a>

        <a routerLink="exam_centers" routerLinkActive="active" class="nav-item nav-link" *ngIf="user.isStaff">
            Centres</a>

        <a routerLink="exam_center_memberships" routerLinkActive="active" class="nav-item nav-link"
            *ngIf="user.isStaff">
            Adhésions</a>

        <a routerLink="users/supervisor" routerLinkActive="active" class="nav-item nav-link"
            *ngIf="user.isStaff || user.isExamCenterBoss">
            Superviseurs</a>

        <a routerLink="users/corrector" routerLinkActive="active" class="nav-item nav-link" *ngIf="user.isStaff">
            Correcteurs</a>

        <a routerLink="users/student" routerLinkActive="active" class="nav-item nav-link"
            *ngIf="user.isStaff || user.isExamCenterBoss || user.isSupervisor">
            Candidats</a>

        <a routerLink="files" routerLinkActive="active" class="nav-item nav-link">
            Fichiers</a>

        <a routerLink="api" routerLinkActive="active" class="nav-item nav-link" *ngIf="user.isExamCenterBoss">
            API</a>
    </div>
</nav>
<div class="mt-3">
    <router-outlet></router-outlet>
</div>