import {Component, Input} from "@angular/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {ExamSession} from "../../../shared/models/examSession";


@Component({
    selector: "app-session-edit-modal",
    templateUrl: "./session-edit-modal.component.html",
    styleUrls: ["./session-edit-modal.component.scss"]
})
export class SessionEditModalComponent {
    @Input() examSession: ExamSession;

    constructor (
        public activeModal: NgbActiveModal,
    ) {
    }
}
