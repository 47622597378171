import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {environment} from "../../../../environments/environment";
import {ExamCenter} from "../../models/examCenter";

@Injectable({
    providedIn: "root"
})
export class ExamCenterService {

    constructor (private http: HttpClient) { }

    list (short?: boolean, valide?: boolean): Observable<Array<ExamCenter>> {
        let params = {};

        if (short) {
            params = new HttpParams().set(
                "short",
                "True"
            );
        }

        if (valide) {
            params = new HttpParams().set(
                "valide",
                "True"
            );
        }

        return this.http.get<Array<ExamCenter>>(
            `${environment.baseApiUrl}/exam_centers/`,
            {params}
        ).
            pipe(map((res: any) => res.map((d: any) => new ExamCenter(d))));
    }

    update (examCenter: ExamCenter) {
        return this.http.put<ExamCenter>(
            `${environment.baseApiUrl}/exam_centers/${examCenter.id}/`,
            examCenter
        ).pipe(map((res: any) => new ExamCenter(res)));
    }

    create (examCenter: ExamCenter) {
        return this.http.post<ExamCenter>(
            `${environment.baseApiUrl}/exam_centers/`,
            examCenter
        ).pipe(map((res: any) => new ExamCenter(res)));
    }

    destroy (id: number) {
        return this.http.delete<ExamCenter>(`${environment.baseApiUrl}/exam_centers/${id}/`);
    }

    requestExams (id: number, count: number, comment: string) {
        return this.http.post<ExamCenter>(
            `${environment.baseApiUrl}/exam_centers/${id}/request_exams/`,
            {count, comment}
        );
    }
}
